<template>
  <div class="periods">
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-if="!loader && getYears.length"
        :title="$t('periods')"
        :descriptionList="[
            {title: $t('period'), width: 400},
            {title: $t('public'), width: 200, paddingLeft: 0, textAlign: 'center'},
        ]"
        :actionText="$t('add-period')"
        :data="getYears"
        sort
        periods
        hideEditIcon
        ref="table"
        @addRowField="addItem"
        @changeRowField="editItem"
        @deleteRowField="deleteItem"
    />
  </div>
</template>

<script>
import Table from "@/components/ui/UiTable.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "Periods",
  components: {UiNotification, UiLoader, Table},

  data () {
    return {
      year: {},
      loader: true,
      showNotification: false,
      message: '',
    }
  },

  computed: {
    ...mapGetters(['getYears'])
  },

  methods: {
    ...mapActions(['years', 'addYears', 'editYears', 'deleteYears']),
    ...mapMutations(['setYears']),

    loadPage(message) {
      this.setYears([])
      this.years().then((res) => {
        let data = []
        res.forEach(el => {
          let year = {
            name: el.id.toString(),
            id: el.id,
            ord: el.ord,
            isPublic: el.isPublic
          }
          data.push(year)
        })
        setTimeout(() => {
          this.showNotification = true
          this.loader = false;
          this.message = message
          this.setYears(data)
        }, 500)
      })
    },

    callYearsApi () {
      this.setYears([])
      this.years().then((res) => {
        let data = []
        res.forEach(el => {
          let year = {
            name: el.id.toString(),
            id: el.id,
            ord: el.ord,
            isPublic: el.isPublic
          }
          data.push(year)
        })
        this.loader = false;
        this.setYears(data)
      })
    },

    addItem (data) {
      this.loader = true
      let payload = {
        id: +data.name || +data.id,
        ord: +data.ord,
        isPublic: data.isPublic
      }

      this.addYears(payload).then(() => {
        this.loadPage(this.$t('period-successfully-added'))
      })
    },

    deleteItem (data) {
      this.loader = true
      this.deleteYears({itemId: data.id}).then(() => {
        this.loadPage(this.$t('period-successfully-deleted'))
      })
    },

    editItem (data) {
      this.loader = true
      let payload = {
        itemId: data.id,
        id: +data.name,
        ord: +data.ord,
        isPublic: data.isPublic
      }
      this.editYears(payload).then(() => {
        this.loadPage(this.$t('period-successfully-updated'))
      })
     }
  },

  mounted() {
    this.callYearsApi()
  }
}
</script>

<style lang="scss" scoped>
.periods {
  width: 100%;
}
</style>